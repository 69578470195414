export enum TrustedModerationStatus {
  Moderation = 1,
  Active = 2,
  Decline = 3,
  Deactivate = 4,
  Blocked = 5,
  Re_moderation = 6,
  Prepared = 10,
}
export interface TrustedUser {
  id: number;
  external_id: number;
  avatar: string;
  created_at: string;
  moderation_status: TrustedModerationStatus;
  is_test: number;
  is_online: boolean;
  gift_available: boolean;
  profile_ids: number[];
  supply: {
    id: number;
    name: string;
    site_ids: number[] | null;
  };
  site_ids: number[];
  documents: TrustedUserDocuments;
  other_details: TrustedUserDetailsModeration;
  public_photos: TrustedUserMedia[];
  private_photos: TrustedUserMedia[];
  private_library: TrustedUserMedia[];
  videos: TrustedUserMedia[];
  options?: any;
}

export type TrustedUserDocuments = {
  date_of_birth: string;
  name: string;
  last_name: string;
  email: string;
  city_id: {
    id: number;
    name: string;
    region: string;
    country: string;
  };
  country_id: {
    id: number;
    geoname_id: number;
    iso2: string;
    name: string;
    iso3: string;
    parent_country_id: number | null;
  };
  passport_number: string;
  passport_scan: string;
  verification_documents: string[];
  moderation_status: boolean;
  decline_reason: string;
};

export type TrustedUserDetailsModeration = TrustedUserDetails & {
  moderation_status: boolean;
  decline_reason: string;
};

export type TrustedUserDetails = {
  gender: number;
  body_type: string;
  height: string;
  weight: string;
  color_of_eyes: string;
  color_of_hair: string;
  marital_status: string;
  count_children: string;
  education: string;
  english_level: string;
  other_languages: string[];
  religion: string;
  smoking: string;
  drinking: string;
  traits: string[];
  hobbies: string[];
  personality_type: string;
  goal: string[];
  people_aged_min: number;
  people_aged_max: number;
  occupation: string;
  about_me: string;
};

export type TrustedUserMedia = {
  id: number;
  file_url: string;
  preview_url: string;
  status_id: number;
  is_erotic: boolean;
  type: number;
  created_at: string;
  updated_at: string;
};

export type TrustedUserModerationMedia = {
  id: number;
  status_id: number;
};

export type TrustedUserModerationStatuses = {
  document_status: boolean;
  document_decline_reason: string;
  other_detail_status: boolean;
  other_detail_decline_reason: string;
  public_photos: TrustedUserMedia[];
  private_photos: TrustedUserMedia[];
  videos: TrustedUserMedia[];
  private_library: TrustedUserMedia[];
};
