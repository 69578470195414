import { FC, useState } from 'react';
import 'react-medium-image-zoom/dist/styles.css';
import {
  BooleanField,
  DateField,
  TextField,
  useNotify,
  useRefresh,
} from 'react-admin';
import {
  TrustedModerationStatus,
  TrustedUser,
} from 'types/trustedUser/trustedUserModeration';
import { Card, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SiteIdsSelectBlock } from './SiteIdsSelectBlock';
import ToggleActivateButton from 'layout/buttons/ToggleActivateButton';
import { httpClient } from 'httpClient';
import ErrorButton from 'layout/buttons/ErrorButton';
import { useHistory } from 'react-router';
import { green } from '@material-ui/core/colors';

interface Props {
  record: TrustedUser;
  userCount?: number;
  isOnline?: boolean;
}

const useStyles = makeStyles((theme) => ({
  asideCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '16.66%',
    padding: '10px',
    border: 'none',
    overflowY: 'scroll',
  },
  onlineStatus: {
    width: '10px',
    height: '10px',
    marginLeft: '5px',
    backgroundColor: 'green',
    borderRadius: '50%',
  },
  bottomContainer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  giftAvailable: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    minWidth: '100px',
    margin: '4px',

    '&:disabled': {
      backgroundColor: green[50],
    },
  },
}));

export const ShortInfoBar: FC<Props> = ({ record, userCount, isOnline }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const history = useHistory();
  const notify = useNotify();

  const [isUpdatingTestStatus, setIsUpdatingTestStatus] = useState(false);
  const [isUpdatingModerationStatus, setIsUpdatingModerationStatus] =
    useState(false);

  const changeTestStatus = () => {
    if (record) {
      setIsUpdatingTestStatus(true);

      httpClient
        .put(`/trusted-user/${record.id}/test`, {
          is_test: !record.is_test,
        })
        .then(() => {
          refresh();

          setIsUpdatingTestStatus(false);
        });
    }
  };

  const handleClickReModeration = () => {
    setIsUpdatingModerationStatus(true);

    if (record) {
      httpClient.put(`/trusted-user/${record.id}/re-moderation`).then(() => {
        setIsUpdatingModerationStatus(false);
        history.push(`/trusted-user/re-moderation/${record.id}`);
      });
    }
  };

  const handleClickActivateProfile = () => {
    setIsUpdatingModerationStatus(true);

    if (record) {
      httpClient
        .post(`trusted-user/published/${record.id}`, {})
        .then(() => {
          setIsUpdatingModerationStatus(false);
          refresh();
        })
        .catch((error) => {
          setIsUpdatingModerationStatus(false);

          notify(
            error.message || 'Something went wrong try change again',
            'error',
          );
        });
    }
  };

  if (!record) return null;

  return (
    <Card className={classes.asideCard}>
      <span style={{ marginBottom: '6px' }}>
        Id: <TextField source="id" />
      </span>
      <span style={{ marginBottom: '6px' }}>
        Carthage Id:{' '}
        <TextField source="external_id" style={{ color: 'rgb(59,130,246)' }} />
        <div>
          Supply Id: <TextField source="supply.id" />
        </div>
        Supply Name:{' '}
        <TextField source="supply.name" style={{ fontWeight: 'bold' }} />
      </span>
      <span style={{ marginBottom: '6px' }}>
        Registration Date:
        <br />
        <DateField source="created_at" locales="UK" />
      </span>
      <div className={classes.giftAvailable}>
        Gift available: <BooleanField source="gift_available" />
      </div>
      <CardMedia
        component="img"
        image={record.avatar}
        alt=""
        style={{
          marginTop: '6px',
          maxHeight: '500px',
          maxWidth: '300px',
          objectFit: 'contain',
        }}
      />

      <SiteIdsSelectBlock
        userId={record.id}
        userSiteIds={record.site_ids}
        availableSupplySiteIds={record?.supply.site_ids}
      />

      <div className={classes.bottomContainer}>
        {!!userCount && <p>Moderation queue: {userCount}</p>}
        {isOnline && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            Online <p className={classes.onlineStatus} />
          </div>
        )}

        <ErrorButton
          className={classes.button}
          disabled={
            isUpdatingModerationStatus ||
            record.moderation_status !== TrustedModerationStatus.Prepared
          }
          onClick={handleClickActivateProfile}
        >
          Publish
        </ErrorButton>

        <ToggleActivateButton
          isActivate={!!record.is_test}
          handleActivate={changeTestStatus}
          handleDeactivate={changeTestStatus}
          isLoader={isUpdatingTestStatus}
          activateLabel="Deactivate is_test"
          deactivateLabel="Activate is_test"
        />

        <ErrorButton
          className={classes.button}
          onClick={handleClickReModeration}
          disabled={
            isUpdatingModerationStatus ||
            record.moderation_status ===
              TrustedModerationStatus.Re_moderation ||
            record.moderation_status === TrustedModerationStatus.Moderation
          }
        >
          RE-MODERATE
        </ErrorButton>
      </div>
    </Card>
  );
};
