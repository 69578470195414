import React from 'react';
import { ArrayInput, NumberInput, SimpleFormIterator } from 'react-admin';

interface Props {
  classNames?: {
    postbackConfigTimingItem: string;
  };
  source: string;
}

export const AgeConfig: React.FC<Props> = React.memo(
  ({ classNames, source }) => {
    return (
      <>
        <p style={{ marginTop: '15px' }}>Age configs:</p>
        <ArrayInput
          source={source}
          label=""
          className={classNames?.postbackConfigTimingItem}
          subscription={{}}
        >
          <SimpleFormIterator>
            <NumberInput
              source="min"
              defaultValue={18}
              min={18}
              max={99}
              step={1}
              label="Min age"
            />

            <NumberInput
              source="max"
              defaultValue={99}
              min={18}
              max={99}
              step={1}
              label="Max age"
            />

            <NumberInput
              source="shave"
              defaultValue={0}
              min={0}
              max={100}
              step={1}
              label="Shave %"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </>
    );
  },
);
