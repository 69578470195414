import {
  Partner,
  PostbackEventConfig,
  PartnerConfigEmail,
  PostbackEventConfigTiming,
  SubsourceGroup,
  PostbackEventConfigAges,
} from '../../model-types';
import { PostbackEventType } from './field-choices';
import { httpClient } from '../../httpClient';

const checkUniquenessOfAgeCategories = ({
  min,
  max,
  configListItenIndex,
  ageConfigList,
  errors,
}: {
  min: number;
  max: number;
  configListItenIndex: number;
  ageConfigList: PostbackEventConfigAges[];
  errors: any;
}) => {
  if (configListItenIndex === 0) {
    return;
  }

  if (
    min >= ageConfigList[configListItenIndex - 1].min &&
    min <= ageConfigList[configListItenIndex - 1].max
  ) {
    errors[configListItenIndex] = {
      ...errors[configListItenIndex],
      min: `You already have a config for age ${ageConfigList[configListItenIndex].min}`,
    };
  }

  if (
    max >= ageConfigList[configListItenIndex - 1].min &&
    max <= ageConfigList[configListItenIndex - 1].max
  ) {
    errors[configListItenIndex] = {
      ...errors[configListItenIndex],
      max: `You already have a config for age ${ageConfigList[configListItenIndex].max}`,
    };
  }

  return checkUniquenessOfAgeCategories({
    min,
    max,
    ageConfigList,
    configListItenIndex: configListItenIndex - 1,
    errors,
  });
};

const ageConfigValidation = ({
  errors,
  ageConfigList,
}: {
  errors: any;
  ageConfigList: PostbackEventConfigAges[];
}) => {
  if (ageConfigList.length) {
    let percentage = 0;

    for (let index = 0; index < ageConfigList.length; index++) {
      const ageConfig = ageConfigList[index];

      if (ageConfig?.min > ageConfig?.max) {
        errors[index] = {
          ...errors[index],
          max: `The max age cannot be less than the min age`,
        };
      }

      if (index !== 0) {
        checkUniquenessOfAgeCategories({
          min: ageConfig.min,
          max: ageConfig.max,
          ageConfigList,
          configListItenIndex: index,
          errors,
        });
      }

      if (ageConfig?.min < 18) {
        errors[index] = {
          ...errors[index],
          min: 'Age must be at least 18 years old',
        };
      }

      if (ageConfig?.min > 99) {
        errors[index] = {
          ...errors[index],
          min: 'Age cannot exceed 99 years',
        };
      }

      if (ageConfig?.max < 18) {
        errors[index] = {
          ...errors[index],
          max: 'Age must be at least 18 years old',
        };
      }

      if (ageConfig?.max > 99) {
        errors[index] = {
          ...errors[index],
          max: 'Age cannot exceed 99 years',
        };
      }

      percentage = percentage + ageConfig?.shave;

      if (percentage > 100) {
        errors[index] = {
          ...errors[index],
          shave: 'Total sum from the shave fields should not exceed 100%',
        };
      }
    }
  }
};

export const validationForm = async (partner: Partner) => {
  const errors: any = {};

  if (partner.subsource_groups && partner.subsource_groups.length > 0) {
    const uniqueRows: string[] = [];

    for (let i = 0; i < partner.subsource_groups.length; i++) {
      if (partner.subsource_groups[i]) {
        let subsourceGroup = partner.subsource_groups[i];

        if (!errors.subsource_groups) {
          errors.subsource_groups = [];
        }

        errors.subsource_groups[i] = {};

        let configEmails = subsourceGroup.partner_config_emails ?? [];
        validateEmailConfigs(errors.subsource_groups[i], configEmails);

        if (!subsourceGroup.id) {
          subsourceGroup.partner_id = partner.id;
        }

        await validateSubsourceRules(
          errors.subsource_groups[i],
          subsourceGroup,
        );

        let type = subsourceGroup.event_type ?? null;

        if (!type) {
          errors.subsource_groups[i].event_type = ['Event type required'];
        }
      }
    }
  }

  if (
    partner.postback_event_configs &&
    partner.postback_event_configs.length > 0
  ) {
    const uniqueRows: string[] = [];

    for (let i = 0; i < partner.postback_event_configs.length; i++) {
      if (partner.postback_event_configs[i]) {
        let eventConfig = partner.postback_event_configs[i];

        if (!errors.postback_event_configs) {
          errors.postback_event_configs = [];
        }

        errors.postback_event_configs[i] = {};

        let timings = eventConfig.postback_event_config_timings ?? [];
        validateTimings(errors.postback_event_configs[i], timings);

        let configEmails = eventConfig.partner_config_emails ?? [];
        validateEmailConfigs(errors.postback_event_configs[i], configEmails);

        if (timings.length > 0 && configEmails.length > 0) {
          errors.postback_event_configs[i].partner_config_emails[0].domain = [
            'Can not use email config together with timing config',
          ];
        }

        let subsource_id = eventConfig.subsource_id ?? null;
        let type = eventConfig.event_type ?? null;
        let enabled_percentage = eventConfig.enabled_percentage ?? null;

        let uniqueRow = subsource_id + String(type);
        let hasSimpleErrors = false;

        if (!type) {
          errors.postback_event_configs[i].event_type = ['Event type required'];
          hasSimpleErrors = true;
        }

        if (
          enabled_percentage &&
          (enabled_percentage > 100 || enabled_percentage < 0)
        ) {
          errors.postback_event_configs[i].enabled_percentage = [
            'Not valid field value',
          ];
          hasSimpleErrors = true;
        }

        const ageConfigList = eventConfig.postback_event_config_ages ?? [];
        errors.postback_event_configs[i].postback_event_config_ages = [];

        ageConfigValidation({
          ageConfigList,
          errors: errors.postback_event_configs[i].postback_event_config_ages,
        });

        if (!hasSimpleErrors) {
          if (uniqueRows.indexOf(uniqueRow) > -1) {
            errors.postback_event_configs[i].subsource_id =
              'This subsource with event type is already configured';
            errors.postback_event_configs[i].event_type =
              'This event type with subsource is already configured';
          } else {
            uniqueRows.push(uniqueRow);
          }
        }

        if (needVisitPostback(partner, eventConfig)) {
          errors.postback_event_configs[i].event_type = [
            'Postback for visit not configured',
          ];
        }
      }
    }
  }

  return errors;
};

const validateSubsourceRules = async (
  errors: any,
  subsourceGroup: SubsourceGroup,
) => {
  if (subsourceGroup.subsource_rules) {
    if (!errors.subsource_rules) {
      errors.subsource_rules = [];
    }

    try {
      await httpClient.post('/subsources/validate', subsourceGroup);
    } catch (error) {
      if (error.response.status === 422) {
        for (let key in error.response.data) {
          const index = subsourceGroup.subsource_rules.length - 1;
          errors[key][index] = {};
          errors[key][index].field = error.response.data[key].join(', ');
        }
      }
    }
  }
};

const validateTimings = (errors: any, timings: PostbackEventConfigTiming[]) => {
  const uniqueTimings: any[] = [];
  if (timings.length > 0) {
    for (let j = 0; j < timings.length; j++) {
      if (timings[j]) {
        let eventConfigTiming = timings[j];
        if (!errors.postback_event_config_timings) {
          errors.postback_event_config_timings = [];
        }

        errors.postback_event_config_timings[j] = {};

        let timing = {
          fHour: eventConfigTiming.from_hour ?? 0,
          fMinute: eventConfigTiming.from_minute ?? 0,
          tHour: eventConfigTiming.to_hour ?? 0,
          tMinute: eventConfigTiming.to_minute ?? 0,
        };

        if (
          timing.tHour < timing.fHour ||
          (timing.tHour == timing.fHour && timing.tMinute <= timing.fMinute)
        ) {
          errors.postback_event_config_timings[j].to_hour = [
            'TO date should be greater than FROM date',
          ];
        }

        let enabledPercentage = eventConfigTiming.enabled_percentage || null;
        if (
          enabledPercentage &&
          (enabledPercentage > 100 || enabledPercentage < 0)
        ) {
          errors.postback_event_config_timings[j].enabled_percentage = [
            'Not valid field value',
          ];
        }

        for (let k = 0; k < uniqueTimings.length; k++) {
          let uniqueTiming = uniqueTimings[k];
          if (
            inTimeRange(timing.fHour, timing.fMinute, uniqueTiming) ||
            inTimeRange(timing.tHour, timing.tMinute, uniqueTiming) ||
            inTimeRange(uniqueTiming.fHour, uniqueTiming.fMinute, timing) ||
            inTimeRange(uniqueTiming.tHour, uniqueTiming.tMinute, timing)
          ) {
            errors.postback_event_config_timings[j].from_hour = [
              'Time intersection',
            ];
          }
        }
        uniqueTimings.push(timing);
      }
    }
  }
};

const inTimeRange = (hour: number, minute: number, timeRange: any) => {
  return (
    timeRange.fHour <= hour &&
    hour <= timeRange.tHour &&
    ((hour === timeRange.fHour && minute >= timeRange.fMinute) ||
      (hour === timeRange.tHour && minute <= timeRange.tMinute) ||
      (timeRange.fHour < hour && hour < timeRange.tHour))
  );
};

const validateEmailConfigs = (
  errors: any,
  configEmails: PartnerConfigEmail[],
) => {
  if (configEmails.length > 0) {
    const uniqueDomains: string[] = [];
    let hasDefaultEmailConfig: boolean = false;
    if (!errors.partner_config_emails) {
      errors.partner_config_emails = [];
    }

    for (let j in configEmails) {
      errors.partner_config_emails[j] = {};
      let configEmail = configEmails[j];
      if (!configEmail) {
        continue;
      }
      if (null === configEmail.domain) {
        hasDefaultEmailConfig = true;
      }
      let uniqueDomain = configEmail.domain;
      if (uniqueDomains.indexOf(uniqueDomain) > -1) {
        errors.partner_config_emails[j].domain = [
          'Email config with this domain is already configured',
        ];
      } else {
        uniqueDomains.push(uniqueDomain);
      }

      let enabledPercentage = configEmail.enabled_percentage || null;
      if (
        enabledPercentage &&
        (enabledPercentage > 100 || enabledPercentage < 0)
      ) {
        errors.partner_config_emails[j].enabled_percentage = [
          'Not valid field value',
        ];
      }
    }

    if (!hasDefaultEmailConfig && configEmails[0]) {
      errors.partner_config_emails[0].domain = ['Need default email domain'];
    }
  }
};

export const needVisitPostback = (
  partner: Partner,
  regPostback: PostbackEventConfig,
): boolean => {
  if (PostbackEventType.Reg !== regPostback?.event_type) {
    return false;
  }
  if (!(hasApiBuying(partner) && partner.api_buying_config.is_visit_enabled)) {
    return false;
  }
  return !hasPostbackForSubsource(
    partner.postback_event_configs,
    regPostback.subsource_id,
    PostbackEventType.Visit,
  );
};

const hasPostbackForSubsource = (
  postbacks: PostbackEventConfig[],
  subsourceId: number | null,
  eventType: number,
): boolean => {
  if (!postbacks.length) {
    return false;
  }
  for (let postback of postbacks) {
    if (
      postback &&
      postback.subsource_id === subsourceId &&
      postback.event_type === eventType
    ) {
      return true;
    }
  }
  return false;
};

const hasApiBuying = (partner: Partner): boolean => {
  if (partner.traffic_type !== 'api') {
    return false;
  }
  if (
    !partner.api_buying_config ||
    !(
      partner.api_buying_config.is_reg_enabled ||
      partner.api_buying_config.is_visit_enabled
    )
  ) {
    return false;
  }

  return true;
};
