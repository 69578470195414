export const profileTypes = [
  { id: 'user', name: 'user' },
  { id: 'botclean', name: 'botclean' },
  { id: 'botadult', name: 'botadult' },
  { id: 'system', name: 'system' },
];

export const supplyAgentRoles = [
  { name: 'Client Manager', id: '1' },
  { name: 'Super Admin', id: '2' },
  { name: 'Admin', id: '3' },
];

export const textTranslationsActions = [
  { id: 'disapprove', name: 'Disapprove' },
  { id: 'retranslate', name: 'Retranslate' },
  { id: 'keep', name: 'Keep (do nothing)' },
];

export const costModelChoices = [
  { id: null, name: '< Choose one >' },
  { id: 'cpc', name: 'cpc' },
  { id: 'cpm', name: 'cpm' },
  { id: 'cpl', name: 'cpl' },
  { id: 'cps', name: 'cps' },
  { id: 'revshare', name: 'revshare' },
  { id: 'flatrate', name: 'flatrate' },
];

export const bannerPlacementChoices = [
  { id: 1, name: 'finder horizontal' },
  { id: 2, name: 'finder vertical' },
  { id: 3, name: 'threads' },
  { id: 4, name: 'chat' },
  { id: 5, name: 'interstitial' },
  { id: 6, name: 'click under' },
  { id: 7, name: 'popunder' },
  { id: 8, name: 'floating bubble' },
  { id: 9, name: 'ad video popup' },
  { id: 10, name: 'pic in pic' },
  { id: 11, name: 'notifications horizontal' },
  { id: 12, name: 'chat fml' },
  { id: 13, name: 'partner offer' },
  { id: 14, name: 'redirect' },
  { id: 15, name: 'session fml' },
  { id: 16, name: 'iframe payment' },
  { id: 17, name: 'submit redirect' },
];

export const siteGroupIdChoices = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
];

export const siteIdChoices = [
  { id: '1', name: 'mariadates.com' },
  { id: '2', name: 'placefortalk.com' },
  { id: '3', name: 'latinesse.com' },
  { id: '6', name: 'emberlove.com' },
];

export const notificationTypesChoses = [
  { id: 'news', name: 'news' },
  { id: 'fine', name: 'fine' },
  { id: 'other', name: 'other' },
];

export const comparisonOperators = {
  equal: '=',
  not_equal: '!=',
  less: '<',
  more: '>',
  less_equal: '<=',
  more_equal: '>=',
};

export const conditionOperators = {
  in: 'IN',
  not_in: 'NOT IN',
  like: 'LIKE',
  not_like: 'NOT LIKE',
  is_null: 'IS NULL',
  is_not_null: 'IS NOT NULL',
};

export const moderationStatusChoices = [
  { id: 1, name: 'moderation' },
  { id: 2, name: 'active' },
  { id: 3, name: 'decline' },
  { id: 4, name: 'deactivate' },
  { id: 5, name: 'blocked' },
  { id: 6, name: 're_moderation' },
  { id: 10, name: 'prepared' },
];
